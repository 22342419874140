import React from "react"
import { Container, Table } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const data1 = [
  {
    datum: "03.12.21",
    heim: "SV Assenheim 1",
    gast: "SV Gambach 1",
    ringe: "1473:1496",
  },
  {
    datum: "28.11.21",
    heim: "SV Gambach 1",
    gast: "SV Griedel 1",
    ringe: "1490:1415",
  },
  {
    datum: "14.11.21",
    heim: "SV Butzbach 1",
    gast: "SV Gambach 1",
    ringe: "1401:1487",
  },
  {
    datum: "07.11.21",
    heim: "SV Gambach 1",
    gast: "SV Assenheim 1",
    ringe: "1489:1496",
  },
  {
    datum: "29.10.21",
    heim: "SV Griedel 1",
    gast: "SV Gambach 1",
    ringe: "1448:1493",
  },
  {
    datum: "10.10.21",
    heim: "SV Gambach 1",
    gast: "SG Butzbach 1",
    ringe: "1470:1355",
  },
]

const data2 = [
  {
    datum: "03.12.21",
    heim: "SV Kirch-Göns 1",
    gast: "SV Gambach 2",
    ringe: "993:696",
  },
  {
    datum: "26.11.21",
    heim: "SV Gambach 2",
    gast: "SV Nieder-Wöllstadt 1",
    ringe: "1045:1060",
  },
  {
    datum: "12.11.21",
    heim: "SV Massenheim 1",
    gast: "SV Gambach 2",
    ringe: "1083:1004",
  },
  {
    datum: "05.11.21",
    heim: "SV Gambach 2",
    gast: "SV Kirch-Göns 1",
    ringe: "1047:1009",
  },
  {
    datum: "29.10.21",
    heim: "SV Nieder-Wöllstadt 1",
    gast: "SV Gambach 2",
    ringe: "1020:1036",
  },
  {
    datum: "08.10.21",
    heim: "SV Gambach 2",
    gast: "SV Massenheim 1",
    ringe: "1081:1049",
  },
]

const THeader = () => (
  <thead>
    <tr>
      <th>#</th>
      <th>Datum</th>
      <th>Heim</th>
      <th>Gast</th>
      <th>Ringzahl</th>
    </tr>
  </thead>
)

const TRow = ({ i, result }) => (
  <tr>
    <td>{i}</td>
    <td>{result.datum}</td>
    <td>{result.heim}</td>
    <td>{result.gast}</td>
    <td>{result.ringe}</td>
  </tr>
)

const AktuellesPage = () => {
  return (
    <Layout>
      <SEO title="Aktuelles" lang="de" />
      <Container>
        <h1 className="h3">Aktuelles</h1>
        <hr className="featurette-divider" />
        <p>Lieber Besucher,</p>
        <p>
          leider mussten wir durch die weiterhin vorherrschenden Einschränkungen
          die geplanten Termine für das Jahr 2021 teilweise absagen. Wir bitten
          um Verständnis und hoffen auf eine baldige Normalisierung.
        </p>
        <h2 className="h4 mt-5">
          Absolvierte Wettkämpfe <small>(Stand: 03.02.22)</small>
        </h2>
        <p>
          SV Gambach 1 (
          <a href="https://www.rwk-onlinemelder.de/online/listen/hsvsb28?sel_group_id=45&sel_discipline_id=6&sel_class_id=6&showCombined=0">
            Bezirksklasse
          </a>
          )
        </p>
        <Table striped>
          <THeader />
          <tbody>
            {data1.map((result, i) => (
              <TRow i={data1.length - i} result={result} />
            ))}
          </tbody>
        </Table>
        <p>
          SV Gambach 2 (
          <a href="https://www.rwk-onlinemelder.de/online/listen/hsvsb28?sel_group_id=45&sel_discipline_id=7&sel_class_id=9&showCombined=0">
            Kreisklasse
          </a>
          )
        </p>
        <Table striped>
          <THeader />
          <tbody>
            {data2.map((result, i) => (
              <TRow i={data2.length - i} result={result} />
            ))}
          </tbody>
        </Table>
      </Container>
    </Layout>
  )
}

export default AktuellesPage
